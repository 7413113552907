/* eslint-disable no-unused-vars */

import React, { useContext, useEffect, useState } from "react";

import { HashRouter as BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
// @mui/material
import { ThemeProvider } from "@mui/material/styles";

// @mui components
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, CssBaseline, Snackbar } from "@mui/material";
import { FirebaseConext } from "./context/FirebaseConext";
import { NotificationContext } from "./context/NotificationProvider";
import Alert from "@mui/material/Alert";
import useOnclickOutside from "react-cool-onclickoutside";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AlertTitle } from "@mui/lab";

// themes
import dark from "./assets/theme/dark";
import light from "./assets/theme/light";

// contexts
import { useMode } from "./context/ModeProvider";
import PlayerProvider from "./context/PlayerProvider";
// layouts
import Main from "./layouts/Main";
import { onMessage } from "firebase/messaging";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoutesWrapper from "./components/Security/ProtectedRoutesWrapper";
import AccessDenied from "./components/Security/AccesDenied";

// views
const CoverageTrendsDetails = loadable(
    () => import("./views/Magazine/CoverageTrendsDetails/CoverageTrendsDetails"),
);
const Home = loadable(() => import("./views/Home/Home"));
const Streaming = loadable(() => import("./views/Streaming/Streaming"));
const Artist = loadable(() => import("./views/Artist/Artist"));
const Store = loadable(() => import("./views/Store/Store"));
const SignUp = loadable(() => import("./views/Auth/SignUp"));
const SignIn = loadable(() => import("./views/Auth/SignIn"));
const SignOut = loadable(() => import("./views/Auth/SignOut"));
const NotFound = loadable(() => import("./views/NotFound/NotFound"));
const TV = loadable(() => import("./views/Tv/TV"));
const MainTV = loadable(() => import("./views/Tv/Main/Main"));
const Magazine = loadable(() => import("./views/Magazine/Magazine"));
const DetailsCategory = loadable(() => import("./views/Store/Details/DetailsCategory"));
const DetailsProduct = loadable(() => import("./views/Store/Details/DetailsProduct"));
const ConcertsView = loadable(() => import("./views/Concerts/ConcertsView"));
const CrowdFunding = loadable(() => import("./views/CrowdFunding/CrowdFunding"));
const Profile = loadable(() => import("./views/ArtisProfile/ArtisProfile"));
const TemplatePerfil1 = loadable(() => import("./views/TemplatePerfil1/TemplatePerfil1.jsx"));
const TemplatePerfil2 = loadable(() => import("./views/TemplatePerfil2/TemplatePerfil2"));
const TemplatePerfil3 = loadable(() => import("./views/TemplatePerfil3/TemplatePerfil3"));
const ProPerfil1 = loadable(() => import("./views/ProfessionalProfile/Perfil1/Perfil1"));
const ProPerfil2 = loadable(() => import("./views/ProfessionalProfile/Perfil2/Perfil2"));
const ProPerfil3 = loadable(() => import("./views/ProfessionalProfile/Perfil3/Perfil3"));
const TemplateProfessionalPerfil1 = loadable(
    () => import("./views/TemplateProfessionalProfile/Perfil1/TemplateProPerfil1.jsx"),
);
const TemplateProfessionalPerfil2 = loadable(
    () => import("./views/TemplateProfessionalProfile/Perfil2/TemplateProPerfil2.jsx"),
);
const TemplateProfessionalPerfil3 = loadable(
    () => import("./views/TemplateProfessionalProfile/Perfil3/TemplateProPerfil3.jsx"),
);
const ProfileData = loadable(() => import("./views/ProfileData/ProfileData"));
const ArtistDashboard = loadable(() => import("./views/ArtistDashboard/ArtistDashboard"));
const EventDetails = loadable(() => import("./views/Store/Events/EventDetails"));
const Classes = loadable(() => import("./views/Classes/Classes"));
const NewsDeployment = loadable(() => import("./views/Magazine/NewsDeployment/NewsDeployment"));
const TrendNews = loadable(() => import("./views/Trend/TrendNews"));
const NewsGet = loadable(() => import("./views/Magazine/NewsDeployment/NewsGet/NewsGet"));
const NewsHybridGet = loadable(() => import("./views/Magazine/NewsDeployment/NewsGet/NewsHybridGet"));
const NewsCreator = loadable(() => import("./views/Magazine/NewsDeployment/NewsCreator/NewsCreator"));
const NewsEdit = loadable(() => import("./views/Magazine/NewsDeployment/NewsEdit/NewsEdit"));
const MoreNews = loadable(() => import("./views/Magazine/NewsDeployment/MoreNews/MoreNews"));
const MoreMain = loadable(() => import("./views/Magazine/NewsDeployment/MoreMain/MoreMain"));
const TestEnviroment = loadable(() => import("./components/NewsCard/TestEnviroment"));
const Search = loadable(() => import("./views/Search/Search"));

const MoreShowbiz = loadable(() => import("./views/Magazine/NewsDeployment/MoreShowbiz/MoreShowbiz"));
const MoreDebates = loadable(() => import("./views/Magazine/NewsDeployment/MoreDebates/MoreDebates"));
const MoreStories = loadable(() => import("./views/Magazine/NewsDeployment/MoreStories/MoreStories"));
const MoreTeleRevista = loadable(
    () => import("./views/Magazine/NewsDeployment/MoreTeleRevista/MoreTeleRevista"),
);
const MoreNewLatinMusic = loadable(
    () => import("./views/Magazine/NewsDeployment/MoreNewLatinMusic/MoreNewLatinMusic.jsx"),
);
const MoreRecommendation = loadable(
    () => import("./views/Magazine/NewsDeployment/MoreRecommendation/MoreRecommendation.jsx"),
);
const MusicChannels = loadable(
    () => import("./views/Magazine/NewsDeployment/MusicChannels/MusicChannels"),
);
const RecordingsPage = loadable(
    () => import("./views/Magazine/NewsDeployment/RecordingsPage/RecordingsPage"),
);
const EventNewsPage = loadable(
    () => import("./views/Magazine/NewsDeployment/EventNewsPage/EventNewsPage"),
);
const ForumForDiscussions = loadable(() => import("./views/ForumForDiscussions/ForumForDiscussions"));
const OpinionsImplementation = loadable(
    () => import("./views/Magazine/OpinionsImplementation/OpinionsImplementation"),
);
const Chat = loadable(() => import("./views/Chat/Chat"));
const AudioVideoCall = loadable(() => import("./views/AudioVideoCall/AudioVideoCall"));
const SuccessPaymentProduct = loadable(
    () => import("./views/Store/SuccessPayment/SuccessPaymentProduct"),
);
const SuccessPaymentTicket = loadable(
    () => import("./views/Store/SuccessPayment/SuccessPaymentTicket"),
);
const SuccessPaymentCrowdFunding = loadable(
    () => import("./views/Store/SuccessPayment/SuccessPaymentCrowdFunding"),
);
const DashboardView = loadable(() => import("./views/Editorial/dashboard"));
const DetailsProject = loadable(() => import("./views/CrowdFunding/Details/DetailsProject"));
const NewsSelectionTab = loadable(() => import("./components/Editorial/tabs/NewsSelectionTab"));
const TVStreaming = loadable(() => import("./views/Tv/TVStreaming/TVStreaming"));
const TemplateUserProfile1 = loadable(
    () => import("./views/TemplateUserProfile/TemplateUserProfile1/TemplateUserProfile1.jsx"),
);

// const AccessDenied = loadable(() => import("./components/Security/AccesDenied"));

const App = () => {
    const { modeState } = useMode();

    const [open, setOpen] = useState(false);

    const firebase = useContext(FirebaseConext);
    const context = useContext(NotificationContext);

    const handleClose = () => {
        context.setNotificationState({
            type: "set",
            ntype: "success",
            visible: false,
            message: {},
        });
        setOpen(false);
    };

    const ref = useOnclickOutside(() => {
        handleClose();
    });

    const messaging = onMessage(firebase, (message) => {
        context.setNotificationState({
            type: "set",
            ntype: "success",
            visible: true,
            message: message.notification,
        });
        setOpen(true);
    });

    useEffect(() => {
        document.body.style.overflowX = "hidden";
        document.body.style.transition = "all 200ms ease";
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={modeState.mode === "light" ? light : dark}>
                <PlayerProvider>
                    <CssBaseline />
                    <div>
                        <Snackbar
                            open={open}
                            sx={{
                                zIndex: 9999999,
                            }}
                            autoHideDuration={6000}
                            onClose={handleClose}
                            anchorOrigin={{ horizontal: "right", vertical: "top" }}
                        >
                            <Alert
                                onClose={handleClose}
                                severity={context?.notificationState.type}
                                icon={<NotificationsIcon />}
                            >
                                <AlertTitle>{context?.notificationState.message.title}</AlertTitle>
                                {context?.notificationState.message.body}
                            </Alert>
                        </Snackbar>
                    </div>
                    <Box>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Main />}>
                                    <Route path="denied" element={<AccessDenied />} />

                                    {/* -- Cambio de ruta temporal para mostrar la seccion noticias como pagina inicial -- */}
                                    {/* <Route index element={<Home />} /> */}
                                    <Route index element={<Navigate replace to="/noticias" />} />

                                    <Route
                                        path="editorial"
                                        element={
                                            <ProtectedRoutesWrapper
                                                definedPath={"/editorial"}
                                                redirectPath={"../auth"}
                                                deniedPath={"../denied"}
                                            />
                                        }
                                    >
                                        <Route path="main" element={<NewsSelectionTab />} />
                                        <Route path="control" element={<DashboardView />} />
                                    </Route>
                                    <Route path="/streaming" element={<Streaming />} />
                                    <Route path="/artist" element={<Artist />} />
                                    <Route path="/store">
                                        <Route index element={<Store />}></Route>
                                        <Route path="/store/details/:id">
                                            <Route index element={<DetailsCategory />} />
                                            <Route
                                                path="/store/details/:id/:productID"
                                                element={<DetailsProduct />}
                                            />
                                        </Route>
                                        <Route
                                            path="/store/event/:eventID"
                                            element={<EventDetails />}
                                        />
                                    </Route>
                                    <Route
                                        path="/news_panel"
                                        element={
                                            <ProtectedRoutesWrapper
                                                redirectPath={"../auth"}
                                                deniedPath={"../denied"}
                                                definedPath={"/news_panel"}
                                            />
                                        }
                                    >
                                        <Route path="/news_panel/create" element={<NewsCreator />} />
                                        <Route path="/news_panel/edit/:id" element={<NewsEdit />} />
                                    </Route>
                                    <Route path="altavoztv" element={<TVStreaming />} />
                                    <Route path="noticias">
                                        <Route index element={<NewsDeployment />} />
                                        <Route
                                            path="grabaciones-lanzamientos"
                                            element={<RecordingsPage />}
                                        />

                                        <Route path="eventos" element={<EventNewsPage />} />
                                        <Route path="historias" element={<MoreStories />} />
                                        <Route path="farandula" element={<MoreShowbiz />} />
                                        <Route path="principales" element={<MoreMain />} />
                                        <Route path="ultimahora" element={<MoreNews />} />
                                        <Route path="musicalatina" element={<MoreNewLatinMusic />} />
                                        <Route
                                            path="musicarecomendacion"
                                            element={<MoreRecommendation />}
                                        />
                                        <Route path="tendencias/:id" element={<TrendNews />} />
                                        <Route
                                            path="canales_musica/:id/:formatArtistNameForURL"
                                            element={<MusicChannels />}
                                        />
                                    </Route>
                                    <Route path="/buscar/" element={<Search />} />
                                    <Route path="/test-newsimagecard" element={<TestEnviroment />} />
                                    <Route path="/news_get/:id" element={<NewsGet />} />
                                    <Route path="/news_get/hn/:id" element={<NewsHybridGet />} />
                                    <Route path="/news" element={<Magazine />} />
                                    <Route path="/more_debates" element={<MoreDebates />} />
                                    <Route path="/more_tele_revista" element={<MoreTeleRevista />} />
                                    <Route path="/opinions_all" element={<OpinionsImplementation />} />
                                    <Route path="/forum/:id" element={<ForumForDiscussions />} />
                                    <Route path="/trends_all" element={<CoverageTrendsDetails />} />
                                    <Route path="/details/" element={<Magazine />} />
                                    <Route path="/events" element={<ConcertsView />} />
                                    <Route path="/classes" element={<Classes />} />
                                    <Route path="/financier" element={<CrowdFunding />} />
                                    <Route path="/profileform" element={<ProfileData />} />
                                    <Route path="/dashboard-artist" element={<ArtistDashboard />} />
                                    <Route path="/chat" element={<Chat />} />
                                    <Route path="/audio_video_call" element={<AudioVideoCall />} />
                                    <Route path="/projects/:id" element={<DetailsProject />} />
                                    <Route
                                        path="/payments/success/product"
                                        element={<SuccessPaymentProduct />}
                                    />
                                    <Route
                                        path="/payments/success/crowdfunding"
                                        element={<SuccessPaymentCrowdFunding />}
                                    />
                                    <Route
                                        path="/payments/success/ticket"
                                        element={<SuccessPaymentTicket />}
                                    />
                                </Route>
                                <Route path="/tv" element={<TV />}>
                                    <Route index element={<MainTV />} />
                                </Route>
                                <Route path="auth">
                                    <Route index="index" element={<SignIn />} />
                                    {/* <Route exact path="/auth/sign-up" element={<SignUp />} /> */}
                                    <Route exact path="/auth/sign-out" element={<SignOut />} />
                                </Route>
                                <Route path="/details/:type/:profileId" element={<Profile />} />
                                <Route path="/preview/artist">
                                    <Route
                                        exact
                                        index="index"
                                        path="/preview/artist/propuesta-1"
                                        element={<TemplatePerfil1 />}
                                    />
                                    <Route
                                        exact
                                        index="index"
                                        path="/preview/artist/propuesta-2"
                                        element={<TemplatePerfil2 />}
                                    />

                                    <Route
                                        exact
                                        index="index"
                                        path="/preview/artist/propuesta-3"
                                        element={<TemplatePerfil3 />}
                                    />
                                </Route>
                                <Route path="/preview/professional">
                                    <Route
                                        exact
                                        index="index"
                                        path="/preview/professional/propuesta-1"
                                        element={<TemplateProfessionalPerfil1 />}
                                    />
                                    <Route
                                        exact
                                        index="index"
                                        path="/preview/professional/propuesta-2"
                                        element={<TemplatePerfil2 />}
                                    />
                                    <Route
                                        exact
                                        index="index"
                                        path="/preview/professional/propuesta-3"
                                        element={<TemplateProfessionalPerfil3 />}
                                    />
                                </Route>
                                {/* <Route
                                    path="/preview/user/propuesta-1"
                                    element={<Navigate replace to="/preview/user/propuesta-1#home" />}
                                /> */}
                                <Route
                                    path="/preview/user/propuesta-1"
                                    element={<TemplateUserProfile1 />}
                                />
                                <Route path="*" element={<NotFound />} />
                            </Routes>
                        </BrowserRouter>
                    </Box>
                </PlayerProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;
